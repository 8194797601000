import { Injectable, inject } from '@angular/core';
import { IAzureResourceType } from '../../models/azure-models/azure-resource-type.model';
import { HttpClient } from '@angular/common/http';
import { ApprovalType } from '../../models/enums/approval-type.enum';
import { AppConfigService } from '../config/config.service';
import { Observable, map } from 'rxjs';
import { AutomationProcessCategoryResponse, IAutomationProcessCollectionResponse, IAutomationProcessResponse } from './automation-process.model';
import { ExceptionScopingType, ScopingType } from '../../models/enums/scoping-type.enum';

@Injectable({
  providedIn: 'root',
})
export class AutomationProcessService {
  httpClient = inject(HttpClient);
  config = inject(AppConfigService);
  baseUrl = `${this.config.apiBaseUrl}/api/v1`;

  public getResourceTypes(): IAzureResourceType[] {
    const resourceTypes: IAzureResourceType[] = [
      {
        value: 'Microsoft.Compute/virtualMachines',
        displayName: 'Virtual Machine',
        image: 'azure-virtual-machine',
      },
      {
        value: 'Microsoft.Storage/storageAccounts',
        displayName: 'Storage Account',
        image: 'azure-storage-account',
      },
      {
        value: 'Microsoft.HybridCompute/machines',
        displayName: 'Azure Arc Machine',
        image: 'azure-arc-machine',
      },
      {
        value: 'Microsoft.Web/sites',
        displayName: 'App Service',
        image: 'azure-app-service',
      },
      {
        value: 'Microsoft.Networking/virtualNetworks',
        displayName: 'Virtual Network',
        image: 'azure-virtual-network',
      },
    ];

    return resourceTypes;
  }

  public createAutomationProcess(
    name: string,
    description: string,
    category: string,
    approvalTypes: ApprovalType[],
    scopingTypes: ScopingType[],
    exceptionScopingTypes: ExceptionScopingType[],
    resourceTypes: string[],
    businessApplicationId: number,
  ): Observable<Object> {
    const requestBody = {
      name: name,
      description: description,
      category: category,
      approvalTypes: approvalTypes,
      scopingTypes: scopingTypes,
      exceptionScopingTypes: exceptionScopingTypes,
      resourceTypes: resourceTypes,
      businessApplicationId: businessApplicationId,
    };

    return this.httpClient.post(`${this.baseUrl}/automation-processes`, requestBody);
  }

  public getAutomationProcesses(): Observable<IAutomationProcessResponse[]> {
    return this.httpClient
      .get<IAutomationProcessCollectionResponse>(`${this.baseUrl}/automation-processes`)
      .pipe(map((data: IAutomationProcessCollectionResponse) => data.result));
  }

  public getAutomationProcessCategories(): Observable<AutomationProcessCategoryResponse[]> {
    return this.httpClient.get<AutomationProcessCategoryResponse[]>(`${this.baseUrl}/automation-processes/categories`);
  }
}

<mat-card>
  <mat-card-content>
    <mat-card-title>Select Scoping Type</mat-card-title>
    <div>
      <mat-radio-group [formControl]="formGroup.controls.scopingType" class="scoping-type">
        @for (scopingType of formGroup.value.scopingTypes; track scopingType) {
          <mat-radio-button [value]="scopingType">{{ mapDeploymentScopeType(scopingType) }}</mat-radio-button>
        }
      </mat-radio-group>
    </div>
  </mat-card-content>
</mat-card>
@if (formGroup.controls.scopingType.value === scopingTypeOptions.DigitalPlatform) {
  <digital-platform-scoping [formGroup]="formGroup.controls.digitalPlatform"></digital-platform-scoping>
}
@if (formGroup.controls.scopingType.value === scopingTypeOptions.BusinessApplication) {
  <business-application-scoping [formGroup]="formGroup.controls.businessApplications" [readOnly]="readOnly"></business-application-scoping>
}
@if (formGroup.controls.scopingType.value === scopingTypeOptions.Resources) {
  <resources-scoping [formGroup]="formGroup.controls.resources" [readonly]="readOnly"></resources-scoping>
}
@if (formGroup.controls.scopingType.value === scopingTypeOptions.ManagementGroup) {
  <management-groups [readonly]="readOnly" [formGroup]="formGroup.controls.managementGroup"></management-groups>
}

<mat-card>
  <mat-card-content>
    <mat-card-title>Deployment Scope Reviewers</mat-card-title>
    @if (reviewersLoading) {
      <mat-spinner [diameter]="50" class="m-3"></mat-spinner>
    } @else {
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            <span [style.paddingLeft.px]="20">Name</span>
          </th>
          <td mat-cell *matCellDef="let data">
            <span class="name-container">
              <button
                mat-icon-button
                [style.visibility]="!data.expandable ? 'hidden' : ''"
                [style.marginLeft.px]="data.level * 32"
                (click)="treeControl.toggle(data)"
              >
                <mat-icon class="mat-icon-rtl-mirror">
                  {{ treeControl.isExpanded(data) ? "expand_more" : "chevron_right" }}
                </mat-icon>
              </button>

              <span [style.paddingLeft.px]="5" [style.verticalAlign]="'super'">
                {{ data.displayName }}
              </span>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            <span>Status</span>
          </th>
          <td mat-cell *matCellDef="let data">
            <span class="status-text" [style.display]="'inline-flex'" [style.alignItems]="'center'">
              {{ data.status }}
              <mat-icon class="status-image" [style.marginLeft.px]="5">{{ getStatusIconName(data.status) }}</mat-icon>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="comment">
          <th mat-header-cell *matHeaderCellDef>
            <span>Comment</span>
          </th>
          <td mat-cell *matCellDef="let data">{{ data.comment }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['name', 'status', 'comment']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['name', 'status', 'comment']"></tr>
      </table>
    }
  </mat-card-content>
</mat-card>

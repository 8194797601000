export enum CloudCentralRequestStatus {
  None = 'None',
  Pending = 'Pending',
  Cancelled = 'Cancelled',
  Rejected = 'Rejected',
  Approved = 'Approved',
}

export enum CloudCentralRequestReviewStatus {
  None = 'None',
  Pending = 'Pending',
  Cancelled = 'Cancelled',
  Rejected = 'Rejected',
  Approved = 'Approved',
}

export enum CloudCentralRequestType {
  DeploymentScope = 'DeploymentScope',
  AutomationProcess = 'AutomationProcess',
}

import { Component, Input, OnInit, inject } from '@angular/core';
import {
  DeploymentScopeReviewer,
  DeploymentScopeReviewerOrigin,
  DeploymentScopeReviewerStatus,
  DeploymentScopeReviewerTreeNode,
} from '../../../services/deployment-scope/deployment-scope.model';
import { DeploymentScopesService } from '../../../services/deployment-scope/deployment-scope.service';
import { map, tap } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';

interface FlatTreeNode {
  id: string;
  name: string;
  comment: string;
  status: string;
  displayName: string;
  type: string;
  totalSubscriptions: number;
  expandable: boolean;
  level: number;
}

@Component({
  selector: 'reviewers-list',
  templateUrl: './reviewers-list.component.html',
  styleUrl: './reviewers-list.component.scss',
})
export class ReviewersListComponent implements OnInit {
  deploymentScopesService = inject(DeploymentScopesService);

  @Input() deploymentScopeId: number;

  reviewersLoading: boolean = true;

  treeControl = new FlatTreeControl<FlatTreeNode>(
    (node) => node.level,
    (node) => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this.treeTransformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  treeTransformer(node: DeploymentScopeReviewerTreeNode, level: number): FlatTreeNode {
    let flatNode = {
      expandable: !!node?.children && node?.children?.length > 0,
      level: level,
      comment: node.comment,
      status: node.status,
      displayName: node.displayName,
    } as FlatTreeNode;

    return flatNode;
  }

  getStatusIconName(status: DeploymentScopeReviewerStatus): string {
    switch (status) {
      case DeploymentScopeReviewerStatus.Approved:
        return 'check';
      case DeploymentScopeReviewerStatus.Rejected:
        return 'close';
      case DeploymentScopeReviewerStatus.Pending:
        return 'schedule';
    }
    return '';
  }

  mapOriginDisplayName(value: DeploymentScopeReviewerOrigin): string {
    switch (value) {
      case DeploymentScopeReviewerOrigin.Application:
        return 'Application';
      case DeploymentScopeReviewerOrigin.ManagementGroup:
        return 'Management Group';
      case DeploymentScopeReviewerOrigin.Platform:
        return 'Platform';
      case DeploymentScopeReviewerOrigin.RiskManager:
        return 'Risk Manager';
      case DeploymentScopeReviewerOrigin.SupportGroup:
        return 'Support Group';
      default:
        return '';
    }
  }
  
  ngOnInit(): void {
    this.populateTree();
  }

  populateTree() {
    this.deploymentScopesService
      .getDeploymentScopeReviewers(this.deploymentScopeId)
      .pipe(
        tap(() => (this.reviewersLoading = false)),
        map((reviewers: DeploymentScopeReviewer[]) => {
          const groupedReviewers = reviewers.reduce(
            (grouped, reviewer) => {
              if (!grouped[reviewer.origin]) {
                grouped[reviewer.origin] = [];
              }
              grouped[reviewer.origin].push(reviewer);
              return grouped;
            },
            {} as { [key: string]: DeploymentScopeReviewer[] },
          );

          return Object.keys(groupedReviewers).map((origin) => ({
            origin: origin as DeploymentScopeReviewerOrigin,
            children: groupedReviewers[origin] as DeploymentScopeReviewerTreeNode[],
          }));
        }),
        map((treeNodes: DeploymentScopeReviewerTreeNode[]) => {
          treeNodes.forEach((node) => {
            node.displayName = this.mapOriginDisplayName(node.origin);
            let hasRejected = false;
            let hasApproved = false;
            node.status = DeploymentScopeReviewerStatus.Pending;

            node.children.forEach((child) => {
              child.displayName = `${child.name} (${child.email})`;

              if (child.status === DeploymentScopeReviewerStatus.Rejected) {
                hasRejected = true;
              }
              if (child.status === DeploymentScopeReviewerStatus.Approved) {
                hasApproved = true;
              }
            });

            if (hasRejected) {
              node.status = DeploymentScopeReviewerStatus.Rejected;
            } else if (hasApproved) {
              node.status = DeploymentScopeReviewerStatus.Approved;
            }
          });

          return treeNodes;
        }),
      )
      .subscribe((groupedReviewers: DeploymentScopeReviewerTreeNode[]) => {
        this.dataSource.data = groupedReviewers;
      });
  }
}

import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NextPage } from '@cvx/nextpage';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuComponent } from './shared/components/navigation-menu/menu.component';
import { HomeView } from './shared/views/home-view/home.view';
import { CreateDeploymentScopeView } from './shared/views/create-deployment-scope/create-deployment-scope.view';
import { MaterialUIModule } from './shared/modules/material-ui.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MyProfileView } from './shared/views/my-profile/my-profile.view';
import { AppConfigService } from './shared/services/config/config.service';
import { CreateAutomationProcessView } from './shared/views/create-automation-process/create-automation-process.view';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CalAngularModule } from '@cvx/cal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CalAuthorizationInterceptor } from './shared/services/interceptor/interceptor.service';
import { AutomationProcessSelectorComponent } from './shared/views/create-deployment-scope/automation-process-selector/automation-process-selector.component';
import { TagFilterComponent } from './shared/views/create-deployment-scope/tag-filter/tag-filter.component';
import { ReviewersListComponent } from './shared/views/create-deployment-scope/reviewers-list/reviewers-list.component';
import { ResourcesSelectorComponent } from './shared/views/create-deployment-scope/resources-selector/resources-selector.component';
import { ScopingTypeComponent } from './shared/views/create-deployment-scope/scoping-type/scoping-type.component';
import { ResourcesScopingComponent } from './shared/views/create-deployment-scope/scoping-type/resources-scoping/resources-scoping.component';
import { ApproveOperationDialog } from './shared/views/create-deployment-scope/dialogs/approve-operation/approve-operation-dialog';
import { ManagementGroupsComponent } from './shared/views/create-deployment-scope/scoping-type/management-groups/management-groups.component';
import { BusinessApplicationSelectorComponent } from './shared/views/create-automation-process/business-application-selector/business-application-selector.component';
import { ScopingTypeSelectorComponent } from './shared/views/create-automation-process/scoping-type-selector/scoping-type-selector.component';
import { BuisnessApplicationScopingComponent } from './shared/views/create-deployment-scope/scoping-type/business-application-scoping/business-application-scoping.component';
import { DigitalPlatformScopingComponent } from './shared/views/create-deployment-scope/scoping-type/digital-platform-scoping/digital-platform-scoping.component';
import { ExceptionScopingTypeSelectorComponent } from './shared/views/create-automation-process/exception-scoping-type-selector/exception-scoping-type-selector.component';

export function initConfig(configService: AppConfigService) {
  return () => configService.load();
}

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HomeView,
    CreateDeploymentScopeView,
    CreateAutomationProcessView,
    MyProfileView,
    BusinessApplicationSelectorComponent,
    BuisnessApplicationScopingComponent,
    ScopingTypeSelectorComponent,
    AutomationProcessSelectorComponent,
    TagFilterComponent,
    ReviewersListComponent,
    ResourcesSelectorComponent,
    ScopingTypeComponent,
    ResourcesScopingComponent,
    ApproveOperationDialog,
    ManagementGroupsComponent,
    DigitalPlatformScopingComponent,
    ExceptionScopingTypeSelectorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NextPage,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialUIModule,
    BrowserAnimationsModule,
    NgxMaterialTimepickerModule,
    CalAngularModule.forRoot('assets/config.json'),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CalAuthorizationInterceptor,
      multi: true,
    },
    AppConfigService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {}
  ngBootStrap() {}
}

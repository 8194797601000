import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AppConfigService } from '../config/config.service';
import { Observable, map } from 'rxjs';
import {
  CreateDeploymentScopeResponseModel,
  DeploymentScopeResponseModel,
  DeploymentScopeReviewer,
  DeploymentScopeReviewersResponse,
  IDigitalPlatformScopingModel,
  IResourcesScopingModel,
} from './deployment-scope.model';
import { DeploymentScopeInclusionType } from '../../views/create-deployment-scope/create-deployment-scope.model';
import { ScopingType } from '../../models/enums/scoping-type.enum';

@Injectable({
  providedIn: 'root',
})
export class DeploymentScopesService {
  private httpClient = inject(HttpClient);
  private config = inject(AppConfigService);
  private baseUrl = `${this.config.apiBaseUrl}/api/v1`;

  public createDeploymentScope(
    automationProcessId: number,
    resourceTypes: string[],
    inclusionType: DeploymentScopeInclusionType,
    scopingType: ScopingType,
    tags?: Record<string, string>,
    resourcesScoping?: IResourcesScopingModel,
    digitalPlatformScope?: IDigitalPlatformScopingModel,
    managementGroupIds?: number[],
    businessApplicationIds?: number[],
  ): Observable<CreateDeploymentScopeResponseModel> {
    const requestBody = {
      automationProcessId: automationProcessId,
      resourceTypes: resourceTypes,
      tags: tags,
      resourcesScoping: resourcesScoping,
      digitalPlatformScoping: digitalPlatformScope,
      inclusionType: inclusionType,
      managementGroupIds: managementGroupIds,
      businessApplicationIds: businessApplicationIds,
      scopingType: scopingType,
    };

    return this.httpClient.post<CreateDeploymentScopeResponseModel>(`${this.baseUrl}/deployment-scopes`, requestBody);
  }

  public getDeploymentScope(deploymentScopeId: number): Observable<DeploymentScopeResponseModel> {
    return this.httpClient.get<DeploymentScopeResponseModel>(`${this.baseUrl}/deployment-scopes/${deploymentScopeId}`);
  }

  public getDeploymentScopeReviewers(deploymentScopeId: number): Observable<DeploymentScopeReviewer[]> {
    return this.httpClient
      .get<DeploymentScopeReviewersResponse>(`${this.baseUrl}/deployment-scopes/${deploymentScopeId}/reviewers`)
      .pipe(map((data: DeploymentScopeReviewersResponse) => data.result));
  }

  public rejectDeploymentScope(deploymentScopeId: number, comment: string): Observable<Object> {
    const requestBody = {
      comment: comment,
    };

    return this.httpClient.post(`${this.baseUrl}/deployment-scopes/${deploymentScopeId}/reject`, requestBody);
  }

  public approveDeploymentScope(deploymentScopeId: number, comment: string): Observable<Object> {
    const requestBody = {
      comment: comment,
    };

    return this.httpClient.post(`${this.baseUrl}/deployment-scopes/${deploymentScopeId}/approve`, requestBody);
  }
}

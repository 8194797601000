<main class="container" npSetLayout [pageLayout]="PageLayout.Layout12">
  <form [formGroup]="deploymentScope">
    <mat-card>
      <mat-card-content>
        <mat-card-title>Create New Deployment Scope</mat-card-title>
        <span>Use this form to create a new deployment scope for opting in or opting out Azure resources from cloud offerings.</span>
      </mat-card-content>
    </mat-card>
    <automation-process-selector [automationProcessSelector]="deploymentScope.controls.automationProcess"></automation-process-selector>
    @if (automationProcessIsSelected()) {
    <business-application-selector
      [readonly]="true"
      [businessApplicationId]="businessApplicationId"
      [formGroup]="deploymentScope.controls.businessApplication"
    ></business-application-selector>
    } @if (automationProcessIsSelected()) {
    <mat-card>
      <mat-card-content>
        <mat-card-title>Deployment Scope Inclusion/Exclusion</mat-card-title>
        <mat-radio-group [formControl]="deploymentScope.controls.inclusion" class="options-selector">
          <mat-radio-button [value]="inclusionTypeOptions.Included">Included</mat-radio-button>
          <mat-radio-button [value]="inclusionTypeOptions.Excluded">Excluded</mat-radio-button>
        </mat-radio-group>
      </mat-card-content>
    </mat-card>
    } @if (automationProcessIsSelected()) {
    <resources-selector [formGroup]="deploymentScope.controls.resourceTypes" [readOnly]="readOnlyMode"></resources-selector>
    } @if (automationProcessIsSelected()) {
    <tag-filter [tagFilters]="deploymentScope.controls.tags" [readonly]="readOnlyMode"></tag-filter>
    } @if (automationProcessIsSelected()) {
    <scoping-type #scopingType [formGroup]="deploymentScope.controls.scopingType" [readOnly]="readOnlyMode"></scoping-type>
    } @if (readOnlyMode) {
    <reviewers-list [deploymentScopeId]="deploymentScopeId"></reviewers-list>
    } @if (readOnlyMode) {
    <div class="button-row">
      <button mat-raised-button color="primary" class="mr-3" (click)="onApproveDeploymentScope()">Approve Request</button>
      <button mat-raised-button color="warn" class="mr-3" (click)="onRejectDeploymentScope()">Reject Request</button>
    </div>
    } @else {
    <div class="form-footer button-row">
      @if (formSubmitting) {
      <mat-spinner [diameter]="23" class="m-2 mr-3"></mat-spinner>
      }
      <button mat-raised-button color="primary" class="mr-3" (click)="onSubmitDeploymentScope()" [disabled]="formSubmitting || formSubmitted">
        Submit Deployment Scope
      </button>
      <button mat-raised-button [disabled]="formSubmitting || formSubmitted">Cancel</button>
    </div>
    }
  </form>
</main>

<mat-card>
  <mat-card-content>
    <mat-card-title>Select Deployment Scope for Digital Platform</mat-card-title>
    <div>
      <mat-form-field>
        <mat-label>Digital Platform</mat-label>
        <mat-select [formControl]="formGroup.controls.digitalPlatform" [compareWith]="compareFn">
          @for (digitalPlatform of digitalPlatforms; track digitalPlatform) {
            <mat-option [value]="digitalPlatform">{{ digitalPlatform.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <section class="application-tiers mt-4">
      <label>Application Tiers</label>
      @for (applicationTier of applicationTiers; track applicationTier) {
        <mat-checkbox
          [checked]="formGroup.value.applicationTiers.includes(applicationTier)"
          [value]="applicationTier"
          [disabled]="readOnly"
          (change)="onApplicationTierChecked(applicationTier, $event)"
        >
          {{ applicationTier }}
        </mat-checkbox>
      }
    </section>
  </mat-card-content>
</mat-card>

<main class="container" npSetLayout [pageLayout]="PageLayout.Layout12">
  <form [formGroup]="automationProcessForm" (ngSubmit)="onSubmitAutomationProcess()">
    <mat-card>
      <mat-card-content>
        <mat-card-title>Create New Automation Process</mat-card-title>
        <span>Use this form to register your cloud automation processes.</span>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-content>
        <mat-card-title>Fill in Automation Process information</mat-card-title>
        <mat-form-field>
          <mat-label>Automation Process Name</mat-label>
          <input matInput [formControl]="automationProcessForm.controls.name" />
          @if(automationProcessForm.controls.name.hasError('required')) {
          <mat-error>Name is required.</mat-error>
          } @if(automationProcessForm.controls.name.hasError('maxlength')) {
          <mat-error>Name maximum length is {{ automationProcessForm.controls.name.errors['maxlength'].requiredLength }} characters.</mat-error>
          }
        </mat-form-field>
        <mat-form-field>
          <mat-label>Description</mat-label>
          <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5" [formControl]="automationProcessForm.controls.description"></textarea>
          @if(automationProcessForm.controls.description.hasError('required')) {
          <mat-error>Description is required.</mat-error>
          } @if(automationProcessForm.controls.description.hasError('maxlength')) {
          <mat-error>
            Description maximum length is {{ automationProcessForm.controls.description.errors['maxlength'].requiredLength }} characters.
          </mat-error>
          }
        </mat-form-field>
        <div>
          <mat-label>Approval Types</mat-label>
          <mat-selection-list [formControl]="automationProcessForm.controls.approvalTypes">
            @for (type of approvalTypes; track type.value){
            <mat-list-option [value]="type.value" togglePosition="before">{{ type.displayName }}</mat-list-option>
            }
          </mat-selection-list>
          @if(automationProcessForm.controls.approvalTypes.hasError('required') && automationProcessForm.controls.approvalTypes.touched) {
          <mat-error>At least one approval type is required.</mat-error>
          }
        </div>
      </mat-card-content>
    </mat-card>
    <scoping-type-selector [formGroup]="automationProcessForm.controls.scopingTypes" />
    <exception-scoping-type-selector [formGroup]="automationProcessForm.controls.exceptionScopingTypes" />
    <business-application-selector [formGroup]="automationProcessForm.controls.applicationSelector" />
    <mat-card>
      <mat-card-content>
        <mat-card-title>Select Category</mat-card-title>
        <mat-form-field>
          <mat-label>Category</mat-label>
          <input type="text" matInput [formControl]="automationProcessForm.controls.category" [matAutocomplete]="categoriesAutocomplete" />
          <mat-autocomplete #categoriesAutocomplete="matAutocomplete" [displayWith]="displayCategoryName">
            @for (category of categories; track category.value) {
            <mat-option [value]="category">{{ category.displayName }}</mat-option>
            }
          </mat-autocomplete>
          @if(automationProcessForm.controls.category.hasError('required')) {
          <mat-error>Category is required.</mat-error>
          }
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-content>
        <mat-card-title>Select Azure Resource Types</mat-card-title>
        <mat-form-field>
          <mat-label>Azure Resource Types</mat-label>
          <input
            matInput
            type="text"
            [matAutocomplete]="azureResourceTypeAutocomplete"
            [formControl]="automationProcessForm.controls.resourceTypes"
          />
          <mat-autocomplete #azureResourceTypeAutocomplete="matAutocomplete" (optionSelected)="onResourceTypeSelected($event)">
            @for (resourceType of filteredResourceTypes | async; track resourceType.value) {
            <mat-option [value]="resourceType">
              <mat-icon [svgIcon]="resourceType.image"></mat-icon>
              {{ resourceType.displayName }}
            </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
        <mat-chip-listbox>
          @for (resourceType of selectedResourceTypes; track resourceType.value) {
          <mat-chip (removed)="removeResourceType(resourceType)" [disabled]="isFormSubmitting">
            <mat-icon [svgIcon]="resourceType.image"></mat-icon>
            {{ resourceType.displayName }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          }
        </mat-chip-listbox>
      </mat-card-content>
    </mat-card>
    <div class="button-row">
      <button mat-raised-button color="primary mr-3" [disabled]="automationProcessForm.invalid || isFormSubmitting" type="submit">
        Submit Automation Process
      </button>
      <button mat-raised-button [disabled]="isFormSubmitting">Clear Form</button>
    </div>
  </form>
</main>

import { Component, OnInit, inject } from '@angular/core';
import { PageLayout } from '@cvx/nextpage';
import { ApprovalType, ApprovalTypeMapper } from '../../models/enums/approval-type.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AutomationProcessService } from '../../services/automation-process/automation-process.service';
import { IAzureResourceType } from '../../models/azure-models/azure-resource-type.model';
import { Observable, catchError, finalize, map, startWith, tap, throwError } from 'rxjs';
import { ServiceNowResourcesService } from '../../services/service-now-resources.service/service-now-resources.service';
import { ToastService } from '../../services/toast/toast.service';
import { BusinessApplicationSelectorComponent } from './business-application-selector/business-application-selector.component';
import { ScopingTypeSelectorComponent } from './scoping-type-selector/scoping-type-selector.component';
import { ExceptionScopingTypeSelectorComponent } from './exception-scoping-type-selector/exception-scoping-type-selector.component';

@Component({
  selector: 'create-automation-process-view',
  templateUrl: './create-automation-process.view.html',
  styleUrls: ['./create-automation-process.view.scss'],
})
export class CreateAutomationProcessView implements OnInit {
  automationProcessService = inject(AutomationProcessService);
  serviceNowService = inject(ServiceNowResourcesService);
  toastService = inject(ToastService);

  PageLayout = PageLayout;
  isFormSubmitting: boolean = false;

  automationProcessForm = new FormGroup({
    name: new FormControl('', { validators: [Validators.required, Validators.maxLength(128)] }),
    description: new FormControl('', { validators: [Validators.required, Validators.maxLength(2000)] }),
    category: new FormControl<AutomationProcessCategory>(null, Validators.required),
    approvalTypes: new FormControl<ApprovalType[]>([], Validators.required),
    scopingTypes: ScopingTypeSelectorComponent.buildFormGroup(),
    exceptionScopingTypes: ExceptionScopingTypeSelectorComponent.buildFormGroup(),
    resourceTypes: new FormControl(''),
    digitalPlatform: new FormControl(''),
    supportGroup: new FormControl(''),
    applicationSelector: BusinessApplicationSelectorComponent.buildFormGroup(),
  });

  approvalTypes: { value: ApprovalType; displayName: string }[] = [];
  categories: AutomationProcessCategory[] = [];

  filteredResourceTypes: Observable<IAzureResourceType[]>;
  selectedResourceTypes: IAzureResourceType[] = [];
  resourceTypes = this.automationProcessService.getResourceTypes();

  ngOnInit(): void {
    this.approvalTypes = Object.values(ApprovalType).map((value: ApprovalType) => {
      return {
        value: value,
        displayName: ApprovalTypeMapper.toDisplayString(value),
      };
    });

    this.filteredResourceTypes = this.automationProcessForm.controls.resourceTypes.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterResourceTypes(value)),
    );

    this.automationProcessService.getAutomationProcessCategories().subscribe((sub) => {
      this.categories = sub;
    });
  }

  onSubmitAutomationProcess(): void {
    const value = this.automationProcessForm.value;

    const name = value.name;
    const description = value.description;
    const category = value.category;
    const approvalTypes = value.approvalTypes;
    const resourceTypes = this.selectedResourceTypes.map((value: IAzureResourceType) => value.value);
    const businessApplicationId = value.applicationSelector.application.id;
    const scopingTypes = value.scopingTypes.scopingTypes;
    const exceptionScopingTypes = value.exceptionScopingTypes.exceptionScopingTypes;

    this.automationProcessForm.disable();
    this.isFormSubmitting = true;

    this.automationProcessService
      .createAutomationProcess(
        name,
        description,
        category.value,
        approvalTypes,
        scopingTypes,
        exceptionScopingTypes,
        resourceTypes,
        businessApplicationId,
      )
      .pipe(
        catchError((err) => {
          this.toastService.showError('Failed to create automation process.');

          return throwError(() => err);
        }),
        finalize(() => {
          this.automationProcessForm.enable();
          this.isFormSubmitting = false;
        }),
      )
      .subscribe((sub) => {
        this.toastService.showSuccess('Automation process created!');
      });
  }

  private _filterResourceTypes(value: string): IAzureResourceType[] {
    // temporary workaround until root cause is found
    if (typeof value !== 'string') {
      value = '';
    }
    const remainingElements = this.resourceTypes.filter((filterValue) => this.selectedResourceTypes.indexOf(filterValue) === -1);
    const searchValue = value.toLowerCase();

    return remainingElements.filter((filterValue) => filterValue.displayName.toLowerCase().search(searchValue) > -1);
  }

  removeResourceType(resourceType: IAzureResourceType): void {
    const index = this.selectedResourceTypes.indexOf(resourceType);
    if (index >= 0) {
      this.selectedResourceTypes.splice(index, 1);
    }

    this.filteredResourceTypes = this.automationProcessForm.controls.resourceTypes.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterResourceTypes(value)),
    );
  }

  onResourceTypeSelected(event: any): void {
    const selectedResourceType = event.option.value as IAzureResourceType;
    if (this.selectedResourceTypes.includes(selectedResourceType)) {
      return;
    }

    this.selectedResourceTypes.push(selectedResourceType);
    this.automationProcessForm.controls.resourceTypes.setValue('');
  }

  displayCategoryName(value: AutomationProcessCategory): string {
    return value?.displayName ?? '';
  }
}

interface AutomationProcessCategory {
  value: string;
  displayName: string;
}
